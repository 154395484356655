import { Link, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Modal, Form, Table } from 'react-bootstrap';
import Select from "react-select";
import { toast } from "react-toastify"
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./Addnewvideo.css";
import Dropzone from "react-dropzone";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { useDropzone } from "react-dropzone";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import getMenuDatalabel from "../../../../../BACKEND/src/layout/Others/Labelmenuapi";
import cancel_video from "../../../components/Images/icons/cancel_video.svg";
import retry_video from "../../../components/Images/icons/retry_video.svg";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";
import NavigationData from "../../../layout/Sidebar/NavigationData";

export default function Addnewvideo(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNextNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [menuDatalabel, setMenuDatalabel] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const data = await getMenuDatalabel();
        setMenuDatalabel(data?.videos_MenuLabel_array);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchData();
  }, []);

  const { label_management } = NavigationData();
  const [datanewppvpprice, setDatanewppvpprice] = useState();
  const [video_id, setVideo_id] = useState("");
  const [m3u8_url, setM3u8_url] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState(1);
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [enable_video_title_image, setEnable_video_title_image] = useState("");
  const [android_tv, setAndroid_tv] = useState("");
  const [video_gif, setVideo_gif] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [access, setAccess] = useState("");
  const [global_ppv, setGlobal_ppv] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [enable, setEnable] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [disk, setDisk] = useState("");
  const [original_name, setOriginal_name] = useState("");
  const [path, setPath] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [views, setViews] = useState("");
  const [Recommendation, setRecommendation] = useState("");
  const [country, setCountry] = useState("");
  const [publish_status, setPublish_status] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [url, setUrl] = useState("");
  const [url_link, setUrl_link] = useState("");
  const [url_linksec, setUrl_linksec] = useState("");
  const [urlEnd_linksec, setUrlEnd_linksec] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [trailer, setTrailer] = useState("");
  const [trailer_filename, setTrailer_filename] = useState("");
  const [trailer_description, setTrailer_description] = useState("");
  const [ads_status, setAds_status] = useState("");
  const [free_duration_status, setFree_duration_status] = useState("");
  const [default_ads, setDefault_ads] = useState("");
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [pre_ads, setPre_ads] = useState("");
  const [mid_ads, setMid_ads] = useState("");
  const [post_ads, setPost_ads] = useState("");
  const [time_zone, setTime_zone] = useState("");
  const [home_genre, setHome_genre] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [user_id, setUser_id] = useState("");
  const [ads_tag_url_id, setAds_tag_url_id] = useState("");
  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");
  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  // const [formDatastore, setFormData] = useState(new FormData());

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_later") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Later"
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const [videosreels, setVideosreels] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setVideosreels(acceptedFiles);
  };

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    slug: "",
    free_duration_time: "",
    free_duration_status: "",
    active: "1",
    year: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",
  });

  const [selectedFiles, setSelectedFiles] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const handleChangeyear = (event) => {
    setValidationMessageyear("");
    setIsValidationHiddenyear(true);
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setValidationMessageyear("");
    setIsValidationHiddenyear(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 1,
      });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 0,
      });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const [userTime, setUserTime] = useState({
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    url_linktym: "",
    urlEnd_linksec: "",
  });

  // const handleInputintrotime = (e) => {
  //   const inputName = e.target.name;
  //   const inputValue = e.target.value;

  //   const formattedInput = inputValue.replace(/\D/g, "").slice(0, 6);
  //   setUserTime({
  //     ...userTime,
  //     [inputName]: formattedInput,
  //   });

  //   // Call the store function immediately when input changes
  //   // storeUserData(inputName, formattedInput);
  // };

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time?.substr(0, 2);
      const minutes = time?.substr(2, 2);
      const seconds = time?.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const handleInputintrotime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setUserTime({
      ...userTime,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= userTime.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= userTime.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= userTime.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= userTime.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  // const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData?.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  // const cancelUpload = () => {
  //   if (cancelFileUpload.current)
  //     cancelFileUpload.current("User has canceled the file upload.");
  // };

  const [uploadCompleteddata, setUploadCompleteddata] = useState(false);

  async function urlsupload() {
    const formData = new FormData();

    formData?.append("video_type", selectedOption);
    formData?.append("m3u8_url", m3u8_url);
    formData?.append("mp4_url", mp4_url);
    formData?.append("embed_code", embed_code);
    // console.log('append data', video_type,)

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/videos-url`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var resultapi = response.data;
          var result = response.data.video_id;
          // console.log('result', result);
          // console.log('result', resultapi);
          setVideo_id(result);
          setUploadCompleteddata(true);
          props?.setApiresponsealert(resultapi);
          props?.setShowalert(true);
        } else {
          if (response.data.status === false) {
            var resultError = response.data;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
            // console.log(resultError)
          }
        }
      });
  }

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };
  const contentFieldChanagedtraildes = (data) => {
    setTrailer_description(data);
    // console.log("datasssssss", data);
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChanges = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData?.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/advertisement-category-depends`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          // console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };


  // const [compressResolutionFormat, setcompressResolutionFormat] = useState({
  //   audios: "",
  //   episode: "",
  //   season: "",
  //   series: "",
  //   live: "",
  //   videos: "",
  // });

  // useEffect(() => {
  //   const fetchCompressImageConfig = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/compress_image`, {
  //         headers: headers,
  //       });
  //       setcompressResolutionFormat(response?.data?.Compress_image);
  //     } catch (error) {
  //       console.error('Error fetching compress image config:', error);
  //     }
  //   };

  //   fetchCompressImageConfig();
  // }, []);


  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
    file4: '',
    file5: ''
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };


  const handleFileChangesss = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.videos === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef4) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file4: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef5) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file5: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };

  const handleDelete = (index) => {

    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      case 5:
        setSelectedFile5(null);
        if (fileInputRef5.current) {
          fileInputRef5.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);
  const [countryblock_country, setCountryblock_country] = useState([]);
  const [
    selectedOptionsCountryblock_country,
    setSelectedOptionsCountryblock_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [video_playlist, setVideo_playlist] = useState([]);
  const [selectedOptionsvideo_playlist, setSelectedOptionsvideo_playlist] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
        { headers: headers }
      );
      setCountryblock_country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setRelated_videos(response?.data?.Related_videos);
      setVideo_playlist(response?.data?.all_video_playlist);
      setLanguage(response?.data?.video_language);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response?.data?.video_artist);
      setCategory(response?.data?.video_category);
      setInputValueAPI(response?.data?.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      // setAlbum(response?.data?.audio_albums);
      const subtitlesmap = response?.data?.video_subtitle;
      setSubtitlesmap(subtitlesmap);
      var age_restrictdata = response?.data?.Age_Restrict;

      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      setUser_access(result);
      var InappPurchase = response?.data?.InappPurchase;
      setInappPurchase(InappPurchase);


      var Defaultppv_gobal_price = response?.data?.ppv_gobal_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    fetchOptions();
  }, []);
  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };
  const handleSelectChangevideo_playlist = (selectedValues) => {
    setSelectedOptionsvideo_playlist(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };
  const handleSelectChangeCountryblock_country = (selectedValues) => {
    setSelectedOptionsCountryblock_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect


  const [validationMessagefree_duration_time, setValidationMessagefree_duration_time] = useState('');
  const [isValidationHiddenfree_duration_time, setIsValidationHiddenfree_duration_time] = useState(true);


  const free_duration_timeInputRef = useRef(null);

  const handleInputdatatime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInputenabletime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.checked ? 1 : 0;

    setEditUser({
      ...editUser,
      [inputName]: inputValue,
    });
  };


  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) === index);
    setSearchTags(cleanedTags);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageyear, setValidationMessageyear] = useState("");
  const [isValidationHiddenyear, setIsValidationHiddenyear] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessageimage, setValidationMessageimage] = useState("");
  const [isValidationHiddenimage, setIsValidationHiddenimage] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const handleAccess = (data) => {
    setAccess(data)
    setValidationMessageAccess("");
    setIsValidationHiddenAccess(true);
  }
  const titleInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const accessInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function videoupload() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (editUser?.year === "") {
      setValidationMessageyear("Year cannot be empty.");
      setIsValidationHiddenyear(false);
      focusInputRef = yearInputRef;
    }
    if (selectedOptionscategory?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    // if (selectedOptionslanguage?.length === 0) {
    //   setValidationMessagelanguage("Please select at least one Language.");
    //   setIsValidationHiddenlanguage(false);
    //   focusInputRef = languageInputRef;
    // }
    if (!access) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }


    // Reset validation messages
    setValidationMessagefree_duration_time("");
    setIsValidationHiddenfree_duration_time(true);

    if (editUser?.free_duration_status === 1) {
      // Validate free_duration_time only if free_duration_status is active (1)
      if (!editUser?.free_duration_time) {
        setValidationMessagefree_duration_time("If Free Duration Status is active, Free Duration Time is mandatory.");
        setIsValidationHiddenfree_duration_time(false);
        focusInputRef = free_duration_timeInputRef;
        return;
      }

      // Additional validation for free_duration_time format can be added here if needed
      // e.g., regex check for HH:MM:SS format
    }

    let valid = true;

    if (compressResolutionFormat?.videos === 1) {
      if (!selectedFile1) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile4) {
        setError(prevErrors => ({ ...prevErrors, file4: 'Video title image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
      if (!selectedFile5) {
        setError(prevErrors => ({ ...prevErrors, file5: 'Video title image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!valid) {
      return;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputfree_duration_statusdata =
      free_duration_status?.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const editInputglobal_ppv = global_ppv?.global_ppv;
    var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
    var global_ppvdata = dataglobal_ppv;

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputenable_video_title_image =
      enable_video_title_image?.enable_video_title_image;
    var dataenable_video_title_image = JSON.stringify(
      editInputenable_video_title_image
    );
    var enable_video_title_imagedata = dataenable_video_title_image;

    console.log(modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price, "modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price")
    const formDatastore = new FormData();

    formDatastore.append("video_id", video_id);
    formDatastore.append("title", editUser?.title);
    formDatastore.append("slug", autoFillValuetitle);
    formDatastore.append("type", type);
    formDatastore.append("active", aactivedata ? parseInt(aactivedata, 10) : 1);
    formDatastore.append("status", status ? parseInt(status, 10) : 0);
    // formDatastore.append("draft", draft);
    formDatastore.append("rating", rating ? parseInt(rating, 10) : 0);
    formDatastore.append("duration", editUser?.duration);
    formDatastore.append("details", details);
    formDatastore.append("description", description);
    formDatastore.append("android_tv", android_tv);
    formDatastore.append("video_gif", video_gif);
    formDatastore.append("search_tags", searchTags);
    formDatastore.append("access", access);
    formDatastore.append("global_ppv", checkboxChecked === true ? 1 : 0 || global_ppvdata ? parseInt(global_ppvdata, 10) : 0);

    if (global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0) {
      formDatastore.append("ppv_price", inputValue);
    } else {
      formDatastore.append("ppv_price", datanewppvpprice?.price);
    }

    formDatastore.append("ios_ppv_price", editUser?.ios_ppv_price);
    const enablefeatureddata = Number.isNaN(parseInt(featureddata, 10)) ? 0 : parseInt(featureddata, 10);
    formDatastore.append("featured", enablefeatureddata);
    const enablebannerdata = Number.isNaN(parseInt(bannerdata, 10)) ? 0 : parseInt(bannerdata, 10);
    formDatastore.append("banner", enablebannerdata);
    const enablefooterdata = Number.isNaN(parseInt(footer, 10)) ? 0 : parseInt(footer, 10);
    formDatastore.append("footer", enablefooterdata);

    formDatastore.append("enable", enable ? parseInt(enable, 10) : 0);
    formDatastore.append("webm_url", webm_url);
    formDatastore.append("ogg_url", ogg_url);
    formDatastore.append("disk", disk);
    formDatastore.append("original_name", original_name);
    formDatastore.append("path", path);
    formDatastore.append("stream_path", stream_path);
    formDatastore.append("processed_low", processed_low);
    formDatastore.append(
      "converted_for_streaming_at",
      converted_for_streaming_at
    );
    formDatastore.append("views", views ? parseInt(views, 10) : 0);
    formDatastore.append("year", editUser?.year);

    formDatastore.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formDatastore.append(
      "block_country_id",
      JSON.stringify(
        selectedOptionsCountryblock_country?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formDatastore.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formDatastore.append("age_restrict", age_restrict);
    // formDatastore.append(
    //   "related_videos_title",
    //   JSON.stringify(
    //     selectedOptionsRelated_videos?.map((option) => option?.value)
    //   )
    // );
    formDatastore.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "video_playlist_id",
      JSON.stringify(
        selectedOptionsvideo_playlist?.map((option) => option?.value)
      )
    );

    formDatastore.append("Recommendation", Recommendation);
    formDatastore.append("country", country);
    formDatastore.append("publish_status", publish_status ? parseInt(publish_status, 10) : 0);
    formDatastore.set("publish_type", publishType);

    formDatastore.append("skip_start_time", userTime?.skip_start_time);
    formDatastore.append("skip_end_time", userTime?.skip_end_time);
    formDatastore.append("skip_start_session", userTime?.skip_start_session);
    formDatastore.append("recap_start_time", userTime?.recap_start_time);
    formDatastore.append("recap_end_time", userTime?.recap_end_time);
    formDatastore.append("recap_start_session", userTime?.recap_start_session);

    formDatastore.append("url_linktym", userTime?.url_linktym);
    formDatastore.set("publish_time", publishTime);
    formDatastore.set("publish_type", publishType);

    formDatastore.append("pdf_files", pdf_files);
    formDatastore.append("reels_thumbnail", reels_thumbnail);
    const enableVideoTitleImageValue = Number.isNaN(parseInt(enable_video_title_imagedata, 10)) ? 0 : parseInt(enable_video_title_imagedata, 10);
    formDatastore.append("enable_video_title_image", enableVideoTitleImageValue);
    // formDatastore.append(
    //   "enable_video_title_image",
    //   enable_video_title_image ? parseInt(enable_video_title_image, 10) : 0);
    formDatastore.append("reelvideo", reelvideo);
    formDatastore.append("url", url);
    formDatastore.append("url_link", url_link);
    formDatastore.append("url_linksec", url_linksec);
    formDatastore.append("urlEnd_linksec", urlEnd_linksec);
    formDatastore.append("trailer_type", trailer_type);
    formDatastore.append("trailer", selectedFiles);
    formDatastore.append("trailer_description", trailer_description);
    formDatastore.append("ads_status", ads_status);
    formDatastore.append("free_duration_status", editUser?.free_duration_status ? parseInt(editUser?.free_duration_status, 10) : 0);
    formDatastore.append("free_duration_time", editUser?.free_duration_time);
    formDatastore.append("default_ads", default_ads);
    formDatastore.append("pre_ads_category", pre_ads_category);
    formDatastore.append("mid_ads_category", mid_ads_category);
    formDatastore.append("post_ads_category", post_ads_category);
    formDatastore.append("pre_ads", pre_ads);
    formDatastore.append("mid_ads", mid_ads);
    formDatastore.append("post_ads", post_ads);
    formDatastore.append("time_zone", time_zone);
    formDatastore.append("home_genre", home_genre);
    formDatastore.append("in_menu", in_menu);
    formDatastore.append("uploaded_by", uploaded_by);
    formDatastore.append("user_id", user_id);
    formDatastore.append("ads_tag_url_id", ads_tag_url_id ? parseInt(ads_tag_url_id, 10) : 0);
    // formDatastore.append("related_videos_title", 1);
    formDatastore.append("draft", 1);

    formDatastore.append("ads_position", selectedAdsPosition?.value);
    formDatastore.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let fourthApiStatus = false;
    let fifthApiStatus = false;
    let sixthApiStatus = false;
    let seventhApiStatus = false;
    let eighthApiStatus = false;
    let ninethApiStatus = false;

    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/video-store`,
        formDatastore,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;
        var result = response?.data?.video_id;

        const imageFormData = new FormData();
        imageFormData.append("video_id", video_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("video_tv_image", selectedFile3);
        }
        if (selectedFile4) {
          imageFormData.append("video_title_image", selectedFile4);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
          imageFormData,
          { headers: headers }
        );

        const formData1 = new FormData();

        videosreels.forEach((video, index) => {
          formData1.append(`reels_videos[${index}]`, video);
        });

        formData1.append("video_id", video_id);

        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-reels`,
          formData1,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("video_id", video_id);

        Object.keys(videosubtitles).forEach((language, index) => {
          formDatasubtitles.append(
            `short_code[${index}]`,
            Subtitlesmap[index].short_code
          );
          formDatasubtitles.append(
            `language[${index}]`,
            Subtitlesmap[index].language
          );
          videosubtitles[language].forEach((file) => {
            formDatasubtitles.append(`subtitles[${index}]`, file);
          });
        });

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos/subtitles`,
          formDatasubtitles,
          { headers: headers }
        );

        const formDatareel = new FormData();

        formDatareel.append("video_id", video_id);
        // formData1.append("reels_videos", reelvideo);
        if (selectedFile5) {
          formDatareel.append("reels_thumbnail", selectedFile5);
        }

        const formDatareelresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-reels-thumbnail`,
          formDatareel,
          { headers: headers }
        );

        const formDaEpaper = new FormData();

        formDaEpaper.append("video_id", video_id);
        formDaEpaper.append("pdf_file", pdf_files);

        const formDaEpaperresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Epaper-video`,
          formDaEpaper,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("video_id", video_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos-SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatatrailerurl = new FormData();

        formDatatrailerurl.append("video_id", video_id);
        formDatatrailerurl.append("mp4_url", trailer);
        formDatatrailerurl.append("m3u8_trailer", trailer);
        formDatatrailerurl.append("embed_url", trailer);
        formDatatrailerurl.append("trailer_type", trailer_type);

        const formDatatrailerurlresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
          formDatatrailerurl,
          { headers: headers }
        );

        const formDtrailervideo = new FormData();

        formDtrailervideo.append("video_id", video_id);
        formDtrailervideo.append("trailer", selectedFiles);
        formDtrailervideo.append("trailer_filename", trailer_filename);

        const formDtrailervideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
          formDtrailervideo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formData1response?.data?.status === true) {
          thirdApiStatus = true;
        }
        if (formDatasubtitlesresponse?.data?.status === true) {
          fourthApiStatus = true;
        }
        if (formDatareelresponse?.data?.status === true) {
          fifthApiStatus = true;
        }
        if (formDaEpaperresponse?.data?.status === true) {
          sixthApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
        if (formDatatrailerurlresponse?.data?.status === true) {
          eighthApiStatus = true;
        }
        if (formDtrailervideoresponse?.data?.status === true) {
          ninethApiStatus = true;
        }
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (
      firstApiStatus &&
      secondApiStatus &&
      thirdApiStatus &&
      fourthApiStatus &&
      fifthApiStatus &&
      sixthApiStatus &&
      seventhApiStatus &&
      eighthApiStatus &&
      ninethApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageyear("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        navigate("/video-lists");
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setIsValidationHiddentitle(false);
      setIsValidationHiddenyear(false);
      setValidationMessageslug(false);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
        headers: headers,
      })
      .then((response) => {
        var ads_category = response?.data?.ads_category;
        // setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);

        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(ads_category)
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(async () => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData?.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data.Advertisement?.map((position) => ({
            value: position.id,
            label: position.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-5 mb-5 videoupload">
            <label>Upload Video </label>

            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="video/mp4"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="m3u8_trailer"
                    onChange={(e) => setM3u8_url(e.target.value)}
                    placeholder="m3u8 Trailer"
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNextNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                    onChange={(e) => setMp4_url(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNextNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Embed URL: </label>
                  <div>
                    <p>
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                    onChange={(e) => setEmbed_code(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                  handleNextNext();
                }}
                className="btn btn-primary"
              >
                Proceed to Next
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
  // const [enable_restreampublic, setEnable_restreampublic] = useState("");
  // const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
  //   useState("");

  // function proceednextpublic() {
  //   switch (enable_restreampublic) {
  //     case "publish_now":
  //       return <div></div>;
  //     case "publish_later":
  //       return (
  //         <div className="mb-3">
  //           <label className="m-0">Publish Time</label>
  //           <input
  //             type="datetime-local"
  //             className="form-control"
  //             id="publish_time"
  //             name="publish_time"
  //             onChange={(e) => setPublish_time(e.target.value)}
  //           />
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // }

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-2 mb-5">
              <button onClick={handleNextNext} className="btn btn-primary">
                Proceed to Next{" "}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-12">
            <label className="mb-1 mt-2"> Upload Trailer : </label>

            <div className="file-draganddrop rs-input">
              <div
                {...getRootProps()}
                className={`mt-3 drop-area ${isDragActive ? "active" : ""}`}
              >
                <input {...getInputProps()} id="upload_trailer" />
                {isDragActive ? (
                  <p>Drop the video file here...</p>
                ) : (
                  <p>Drag and drop your video file here</p>
                )}
              </div>
              {selectedFiles && (
                <div>
                  <p>{selectedFiles?.name}</p>
                </div>
              )}
            </div>
            {/* <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-lg rs-input"
                      onChange={(e) => setTrailer(e.target.files[0])}
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                    />
                  </p>
                </div>
                <br />
              </label>
            </div> */}
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="mp3_url"
                    id="mp3_url"
                    onChange={(e) => setTrailer(e.target.value)}
                    placeholder="m3u8 URL"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNextNext(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="mp4_trailer"
                    id="mp4_trailer"
                    placeholder="mp4 Trailer"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="live_mp3_url"
                    id="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }





  // {ppv price with global and customs} 
  // {ppv price with global and customs} 



  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let Country = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              Country = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              Country: Country,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 1, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: video_id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Video/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 



  const [allliveuseraccess, setallliveuseraccess] = useState("");
  // const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>

            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (
              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">{label_management?.videos[71]?.title}</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">{label_management?.videos[71]?.sub_title}</p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          id="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          id="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      name="ppv_price"
                      id="price"
                      readOnly={checkboxChecked}
                      // onChange={(e) => setPpv_price(e.target.value)}
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">{label_management?.videos[68]?.title}</label>
                  <p className="p1">{label_management?.videos[68]?.sub_title}</p>
                  <div className="panel-body ppv_price_ios  mt-2">
                    <select
                      onChange={handleInput}
                      className="form-select"
                      name="ios_ppv_price"
                      id="ios_ppv_price"
                    >
                      <option value="">Choose a PPV Price</option>
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.videos[65]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}                            
                            onClick={() => handleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.videos[66]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}

                        onClick={() => handleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalType === '1' ? `${label_management?.videos[70]?.title}` : `${label_management?.videos[71]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {step === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalType === '1' ? `${label_management?.videos[65]?.title}` : `${label_management?.videos[65]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                disabled={modalType === '1'}
                              />
                            </Form.Group>
                            {modalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.videos[67]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.videos[67]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
                            <Button variant="primary" onClick={handleNext}>
                              Next
                            </Button>
                          </div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.videos[68]?.title}</label>
                              <p className="p1">{label_management?.videos[68]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option
                                      key={key}
                                      value={item?.product_id}
                                      selected={item?.product_id === editUser?.ios_ppv_price}
                                    >
                                      {item?.plan_price}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
                            <Button variant="secondary" onClick={() => setStep(1)}>
                              Previous
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                              Submit
                            </Button>
                          </div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {step === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                            Submit
                          </Button>
                        </div>
                      )}

                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }

  // const handleUpload = async (files) => {
  //   const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   const uploaders = files.map((file) => {
  //     const formData = new FormData();
  //     formData?.append("file", file);

  //     return axios.post(uploadUrl, formData, {
  //       headers: headers,
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         );
  //         setUploadProgress(percentCompleted);
  //       },
  //     });
  //   });

  //   try {
  //     await Promise.all(uploaders);

  //     const responses = await Promise.all(uploaders);
  //     // console.log("Responses after upload:", responses);
  //     if (responses.data.status === true) {

  //     responses.forEach((response, index) => {
  //       console.log(`Response for video ${index + 1}:`, response.data.Videos);
  //       setEditUser(response.data.Videos);
  //       setVideo_id(response.data.video_id);
  //       // Process the response data as needed for each video
  //     });
  //     const uploadedDetails = files.map((file, index) => ({
  //       name: file.name,
  //       size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
  //     }));

  //     var videosapi = responses?.data
  //     setUploadedFiles(uploadedDetails);
  //     setUploadCompleted(true);
  //     setUploadProgress(0);
  //     // alert("All videos uploaded successfully!");
  //     props?.setApiresponsealert(videosapi);
  //       props?.setShowalert(true);

  //     }
  //     else {
  //       if (responses.data.status === false) {
  //       var resultError = responses.data.message;
  //             props?.setApiresponsealert(resultError);
  //             props?.setShowalert(true);
  //             setUploadProgress(0);
  //             // console.log(resultError)
  //       }
  //     }
  //   } catch (error) {
  //     // if (error.data.status === false) {
  //     //   var resultError = error.data.message;
  //     //         props?.setApiresponsealert(resultError);
  //     //         props?.setShowalert(true);
  //     //         // console.log(resultError)
  //     //         setUploadProgress(0);
  //     //   }
  //   }
  // };





  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [progress, setProgress] = useState({});
  const [cancelTokens, setCancelTokens] = useState({});
  const [pausedFiles, setPausedFiles] = useState({});
  const [failedFiles, setFailedFiles] = useState([]);

  const maxRetries = 3;

  const updateProgress = (fileName, percentCompleted) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [fileName]: percentCompleted,
    }));
  };

  const overallProgress = () => {
    const totalFiles = Object.keys(progress).length;
    const totalProgress = Object.values(progress).reduce((acc, value) => acc + value, 0);
    return totalFiles ? totalProgress / totalFiles : 0;
  };

  const uploadFileWithRetry = async (file, retryCount = 0, start = 0) => {
    const formData = new FormData();
    formData?.append('file', file.slice(start), file.name);
    // formData?.append("UploadlibraryID", document.getElementById('UploadlibraryID').value); // Adjust this as needed
    // formData?.append("_token", CSRF_TOKEN);

    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokens(prevTokens => ({ ...prevTokens, [file.name]: cancelTokenSource }));

    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/video-upload-file`, formData, {
        headers: {
          Authorization: 'Bearer ' + access_token,
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Content-Range': `bytes ${start}-${file.size - 1}/${file.size}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(((progressEvent.loaded + start) * 100) / file.size);
          updateProgress(file.name, percentCompleted);
        },
        cancelToken: cancelTokenSource.token
      });

      const uploadedDetail = {
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2),
      };

      setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, uploadedDetail]);

      props?.setApiresponsealert(response.data);
      props?.setShowalert(true);

      if (response.data.status === true) {
        setEditUser(response?.data?.Videos);
        setVideo_id(response?.data?.video_id);
        props?.setApiresponsealert({ status: true, message: `Successfully uploaded ${file.name}` });
      } else {
        props?.setApiresponsealert(response.data);
        props?.setShowalert(true);
        updateProgress(file.name, 0);
      }

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload paused:', file.name);
        setPausedFiles(prevPausedFiles => ({ ...prevPausedFiles, [file.name]: start }));
      } else {
        console.error('Error uploading video:', error);
        if (retryCount < maxRetries) {
          console.log(`Retrying upload for ${file.name} (Attempt ${retryCount + 1})`);
          await uploadFileWithRetry(file, retryCount + 1, start);
        } else {
          props?.setApiresponsealert({ status: false, message: `Failed to upload ${file.name} after ${maxRetries} attempts.` });
          props?.setShowalert(true);
          setFailedFiles(prevFailedFiles => [...prevFailedFiles, file]);
        }
      }
    }
  };

  const handleUpload = async (files) => {
    try {
      setLoading(true);

      const uploadPromises = files.map((file) => {
        const start = pausedFiles[file.name] || 0;
        return uploadFileWithRetry(file, 0, start);
      });

      await Promise.all(uploadPromises);

      setLoading(false);
      setUploadCompleted(true);
    } catch (error) {
      console.error('Error uploading videos:', error);
      setLoading(false);
    }
  };

  const cancelUpload = (fileName) => {
    if (cancelTokens[fileName]) {
      cancelTokens[fileName].cancel(`Upload canceled for ${fileName}`);
      setCancelTokens(prevTokens => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[fileName];
        return updatedTokens;
      });
    }
  };

  const pauseUpload = (fileName) => {
    if (cancelTokens[fileName]) {
      cancelTokens[fileName].cancel(`Upload paused at ${progress[fileName]}`);
      setCancelTokens(prevTokens => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[fileName];
        return updatedTokens;
      });
    }
  };

  const resumeUpload = (file) => {
    if (pausedFiles[file.name] !== undefined) {
      const start = pausedFiles[file.name];
      const fileToResume = new File([file], file.name, { type: file.type });
      uploadFileWithRetry(fileToResume, 0, start);
      setPausedFiles(prevPausedFiles => {
        const updatedPausedFiles = { ...prevPausedFiles };
        delete updatedPausedFiles[file.name];
        return updatedPausedFiles;
      });
    }
  };

  const retryUpload = (file) => {
    setFailedFiles(prevFailedFiles => prevFailedFiles.filter(f => f.name !== file.name));
    handleUpload([file]);
  };






  // const updateProgress = (fileName, progress) => {
  //   setUploadProgress((prevProgress) => ({
  //     ...prevProgress,
  //     [fileName]: progress,
  //   }));
  // };

  // const overallProgress = () => {
  //   const progressValues = Object.values(uploadProgress);
  //   const overallProgress =
  //     progressValues.reduce((acc, val) => acc + val, 0) / progressValues.length;
  //   return isNaN(overallProgress) ? 0 : overallProgress;
  // };

  const [timeskip_intro, setTimeskip_intro] = useState("");
  const [formattedTimeskip_intro, setFormattedTimeskip_intro] = useState("");

  const formatTimeskip_intro = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangeskip_intro = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimeskip_intro(input);

    setTimeskip_intro(formattedInput);
    setFormattedTimeskip_intro(formattedInput);
  };

  const [timesintrostarttime, setTimesintrostarttime] = useState("");
  const [formattedTimesintrostarttime, setFormattedTimesintrostarttime] =
    useState("");

  const formatTimesintrostarttime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesintrostarttime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesintrostarttime(input);

    setTimesintrostarttime(formattedInput);
    setFormattedTimesintrostarttime(formattedInput);
  };

  const [timesintroendtime, setTimesintroendtime] = useState("");
  const [formattedTimesintroendtime, setFormattedTimesintroendtime] =
    useState("");

  const formatTimesintroendtime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesintroendtime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesintroendtime(input);

    setTimesintroendtime(formattedInput);
    setFormattedTimesintroendtime(formattedInput);
  };

  const [timesskiprecaptime, setTimesskiprecaptime] = useState("");
  const [formattedTimesskiprecaptime, setFormattedTimesskiprecaptime] =
    useState("");

  const formatTimesskiprecaptime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskiprecaptime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskiprecaptime(input);

    setTimesskiprecaptime(formattedInput);
    setFormattedTimesskiprecaptime(formattedInput);
  };

  const [timesskipstarttime, setTimesskipstarttime] = useState("");
  const [formattedTimesskipstarttime, setFormattedTimesskipstarttime] =
    useState("");

  const formatTimesskipstarttime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskipstarttime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskipstarttime(input);

    setTimesskipstarttime(formattedInput);
    setFormattedTimesskipstarttime(formattedInput);
  };

  const [timesskipendtime, setTimesskipendtime] = useState("");
  const [formattedTimesskipendtime, setFormattedTimesskipendtime] =
    useState("");

  const formatTimesskipendtime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };


  const handleToggle = () => {
    setActive(prevState => ({ ...prevState, active: prevState.active === 1 ? 0 : 1 }));
  };

  const handleInputChangesskipendtime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskipendtime(input);

    setTimesskipendtime(formattedInput);
    setFormattedTimesskipendtime(formattedInput);
  };







  // { Master Library data}
  // { Master Library data}


  const [itemToDelete, setItemToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const closeModal = () => {
    setItemToDelete(null);
    setModelClose(!closeModal);
  };

  const openModal = () => {
    setModelClose(true);
  };

  const [totalMastercount, setTotalMastercount] = useState();
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [episodeData, setEpisodeData] = useState([]);

  const [filteredVideoData, setFilteredVideoData] = useState([]);
  const [filteredAudioData, setFilteredAudioData] = useState([]);
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [filteredEpisodeData, setFilteredEpisodeData] = useState([]);

  const [selectAllVideo, setSelectAllVideo] = useState(false);
  const [selectAllAudio, setSelectAllAudio] = useState(false);
  const [selectAllLive, setSelectAllLive] = useState(false);
  const [selectAllEpisode, setSelectAllEpisode] = useState(false);

  const [filterVideo, setFilterVideo] = useState("");
  const [filterAudio, setFilterAudio] = useState("");
  const [filterLive, setFilterLive] = useState("");
  const [filterEpisode, setFilterEpisode] = useState("");

  const [itemToDeleteVideo, setItemToDeleteVideo] = useState([]);
  const [itemToDeleteAudio, setItemToDeleteAudio] = useState([]);
  const [itemToDeleteLive, setItemToDeleteLive] = useState([]);
  const [itemToDeleteEpisode, setItemToDeleteEpisode] = useState([]);

  const [modelClose, setModelClose] = useState(false);
  const [modalCloseLive, setModalCloseLive] = useState(false);
  const [modalCloseAudio, setModalCloseAudio] = useState(false);
  const [modalCloseEpisode, setModalCloseEpisode] = useState(false);

  const fetchApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/all-library/index`, {
        headers: headers,
      })
      .then((response) => {
        // const masterAllList = response;
        const videoList = response?.data?.Videos;
        const audioList = response?.data?.Audios;
        const liveList = response?.data?.LiveStream;
        const episodeList = response?.data?.episodes;
        // setMasterOverAllData(masterAllList);
        setTotalMastercount(response?.data?.master_count);
        const updatedVideoList = videoList?.map((item) => {
          const UploadStatus =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1 &&
              item?.publish_type === "publish_now" &&
              item?.status === 1 &&
              item?.draft === 1
              ? "PUBLISHED"
              : item?.active == null &&
                item?.publish_type === "publish_later" &&
                item?.status == null &&
                item?.draft == null
                ? "SCHEDULED"
                : "DRAFT";

          return { ...item, uploaded_by: UploadStatus, active: statusText };
        });
        const updatedLiveList = liveList?.map((item) => {
          const UploadStatus =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1 &&
              item?.publish_type === "publish_now" &&
              item?.status === 1
              ? // &&
              // item?.draft === 1
              "PUBLISHED"
              : item?.active == null &&
                item?.publish_type === "publish_later" &&
                item?.status == null
                ? //  &&
                // item?.draft == null
                "SCHEDULED"
                : "DRAFT";

          return { ...item, uploaded_by: UploadStatus, active: statusText };
        });
        const updatedAudioList = audioList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        const updatedEpisodeList = episodeList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        setVideoData(updatedVideoList);
        setAudioData(updatedAudioList);
        setLiveData(updatedLiveList);
        setEpisodeData(updatedEpisodeList);
        setFilteredVideoData(updatedVideoList);
        setFilteredLiveData(updatedLiveList);
        setFilteredAudioData(updatedAudioList);
        setFilteredEpisodeData(updatedEpisodeList);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(async () => {
    fetchApi();
  }, []);
  ///General Pagination
  const commonPaginationConfig = (sizePerPageList) => {
    return paginationFactory({
      page: 1,
      sizePerPage: 10,
      nextPageText: "Next",
      prePageText: "Prev",
      firstPageText: <MdKeyboardDoubleArrowLeft />,
      lastPageText: <MdKeyboardDoubleArrowRight />,
      showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageList,
    });
  };
  const videoSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredVideoData?.length },
  ];
  const liveSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredLiveData?.length },
  ];
  const audioSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredAudioData?.length },
  ];
  const episodeSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredEpisodeData?.length },
  ];

  ////  Video Started

  const handleClear = () => {
    fetchApi();
    setFilterVideo("");
  };
  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilterVideo(searchTerm);
    const searchTermLower = searchTerm?.toLowerCase();
    const filteredResults = videoData?.filter((item) => {
      return (
        (typeof item?.title === "string" &&
          item?.title.toLowerCase().includes(searchTermLower)) ||
        item?.uploaded_by.toLowerCase().includes(searchTermLower) ||
        (typeof item?.active === "string" &&
          item?.active.toLowerCase().includes(searchTermLower)) ||
        item?.upload_on.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredVideoData(filteredResults);
  };
  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("VIDEO-LIST", 10, 10);
    const columns = ["Index", "ID", "uploaded_by", "status", "banner"];
    const allDataPDF = videoData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
    ]);
    const filterPDF = filteredVideoData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("VIDEO-LIST.pdf");
  };

  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? videoData : filteredVideoData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "VIDEO-LIST.xlsx");
  };

  const exportHeaders = [
    { label: "ID", key: "id" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
  ];


  const fileInputRefreplacevideo = useRef(null);

  const handleClickreplacevideo = (id) => (e) => {
    e.preventDefault();
    // Trigger the file input dialog
    fileInputRefreplacevideo.current.click();
    console.log(id); // Here you have access to row.id
  };

  const handleFileChangereplacevideo = async (event, id) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData?.append('file', file);
    formData?.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/edit-video-upload-file`,
        formData,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleFileChangereplacevideodelete = async (id) => {

    const formData = new FormData();
    // formData?.append('file', file);
    // formData?.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_Baseurl}/admin/videos-delete/${id}`,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const actionFormatter = (data, row) => {
    return (
      <>
        <div className="d-flex">
          <div>
            <div onClick={handleClickreplacevideo(row.id)} className="commonActionPadding">
              <span>
                <img src={retry_video} alt="flogo" width={20} height={20} />
              </span>
            </div>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplacevideo}
              onChange={(e) => handleFileChangereplacevideo(e, row.id)}
            />
          </div>
          <div>
            <div
              onClick={() => handleFileChangereplacevideodelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
            </div>
          </div>
        </div>

        {/* <div className="editdropdown">
          <span className="editdropdown-button">
            <span>
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
          </span>
          <div className="editdropdown-menu">

            <div onClick={handleClickreplace(row.id)} className="commonActionPadding">
              <span>
                <img src={edititem} alt="flogo" width={20} height={20} />
                <span className="ms-2">Replace Video</span>
              </span>
            </div>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplace}
              onChange={(e) => handleFileChangereplace(e, row.id)}
            />
            <div
              onClick={() => singleDelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Delete Video</span>
            </div>
          </div>
        </div> */}
      </>
    );
  };

  const videoFormatter = (data, row) => {
    const truncatedData = row?.title
      ? (row?.title.length > 15 ? row.title.slice(0, 15) + '...' : row?.title)
      : '';
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={data} className="profile" alt="image_all" />
        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText ">{row?.duration}</span>
          <div><span className="dateText"> {row?.upload_on}</span></div>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/videos/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };
  const columns = [
    {
      dataField: "Thumbnail",
      text: "Videos",
      formatter: videoFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Upload by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteVideo([...itemToDeleteVideo, row?.id]);
        } else {
          setItemToDeleteVideo(
            itemToDeleteVideo.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteVideo,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };
  const closeModalvideo = () => {
    setModelClose(!closeModalvideo);
  };
  const openModalvideo = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDeleteVideo([id]);
  };
  const handleAll = () => {
    setSelectAllVideo(!selectAllVideo);
    setItemToDeleteVideo(
      selectAllVideo ? [] : filteredVideoData?.map((item) => item?.id)
    );
  };
  const handleDeleteVideoDatavideo = () => {
    handleConfirmDeletevideo();
    closeModalvideo();
  };
  const handleConfirmDeletevideo = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData?.append("video_id", "[" + itemToDeleteVideo + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/all-library/storeEpisodes`,
        formData,
        { headers: headers }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  //// video code end

  ///Live Stream Started
  const selectRowLive = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteLive([...itemToDeleteLive, row?.id]);
        } else {
          setItemToDeleteLive(itemToDeleteLive?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDeleteLive,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllLive}
        />
      </div>
    ),
  };
  const liveFormatter = (data, row) => {
    const truncatedData = data
      ? (data.length > 15 ? data.slice(0, 15) + '...' : data)
      : '';

    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={row?.Thumbnail} className="profile" alt="image_all" />
        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <div><span className="dateText">{row?.upload_on}</span></div>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/live/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusLiveFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionLiveFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit/add-livestream/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Live</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteLive(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Live</span>
          </div>
        </div>
      </div>
    );
  };

  const liveColumns = [
    {
      dataField: "title",
      text: "Live Videos",
      sort: true,
      formatter: liveFormatter,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusLiveFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionLiveFormatter,
    },
  ];

  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "SLIDER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS",
      "UPLOADED BY",
      "STATUS",
      "BANNER",
      "UPLOAD ON",
    ];
    const allDataPDF = liveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };
  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? liveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };
  const handleFilterChangeLive = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterLive(searchTerm);
    const filteredResults = liveData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredLiveData(filteredResults);
  };
  const handleClearLive = () => {
    fetchApi();
    setFilterLive("");
  };
  const closeLiveModal = () => {
    setModalCloseLive(!modalCloseLive);
  };
  const openModalSingleDeleteLive = (id) => {
    setItemToDeleteLive([id]);
    setModalCloseLive(true);
  };
  const handleDeleteLiveData = () => {
    closeLiveModal();
    liveDeleteOperation();
  };
  const openModalMultiDeleteLive = () => {
    setModalCloseLive(true);
  };

  const handleAllLive = () => {
    setSelectAllLive(!selectAllLive);
    setItemToDeleteLive(
      selectAllLive ? [] : filteredLiveData?.map((item) => item?.id)
    );
  };

  const liveDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData?.append("id", "[" + itemToDeleteLive + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/multi-delete/`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  ////Live Stream End

  ////Audio started
  const selectRowAudio = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteAudio([...itemToDeleteAudio, row?.id]);
        } else {
          setItemToDeleteAudio(
            itemToDeleteAudio.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteAudio,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllAudio}
        />
      </div>
    ),
  };

  const audioFormatter = (data, row) => {

    const truncatedData = data
      ? (data.length > 15 ? data.slice(0, 15) + '...' : data)
      : '';

    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.TV_Thumbnail ? row?.TV_Thumbnail : row?.TV_Thumbnail}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <div><span className="dateText">{row?.upload_on}</span></div>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/music/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterAudio = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };


  const fileInputRefreplaceaudio = useRef(null);

  const handleClickreplaceaudio = (id) => {
    return (e) => {
      e.preventDefault();
      if (fileInputRefreplaceaudio.current) {
        fileInputRefreplaceaudio.current.click();
      } else {
        console.error('fileInputRefreplaceaudio is not assigned properly.');
      }
    };
  };


  const handleFileChangereplaceaudio = async (event, id) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData?.append('file', file);
    formData?.append('audio_id', id); // Append row.id to the formData

    // Set processing and show overlay states to true
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Edit-Upload-Audio`,
        formData,
        {
          headers: headers,
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleFileChangereplaceaudiodelete = async (id) => {

    const formData = new FormData();
    // formData?.append('file', file);
    // formData?.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_Baseurl}/admin/audio/delete/${id}`,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };



  const actionFormatterAudio = (data, row) => {
    return (
      <>
        <div className="d-flex">
          <div>
            <div onClick={handleClickreplaceaudio(row.id)} className="commonActionPadding">
              <span>
                <img src={retry_video} alt="flogo" width={20} height={20} />
              </span>
            </div>
            <input
              type="file"
              accept="audio/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplaceaudio}
              onChange={(e) => handleFileChangereplaceaudio(e, row.id)}
            />
          </div>
          <div>
            <div
              onClick={() => handleFileChangereplaceaudiodelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
            </div>
          </div>
        </div>


        {/* <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-audio/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Audio </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteAudio(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Audio</span>
          </div>
        </div>
      </div> */}
      </>

    );
  };
  const columnsAudio = [
    {
      dataField: "title",
      text: "Audios",
      formatter: audioFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterAudio,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterAudio,
    },
  ];
  const exportHeadersAudio = [
    { label: "ID", key: "id" },
    { label: "AUDIOS", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "BANNER", key: "banner" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFAudio = (get) => {
    const doc = new jsPDF();
    doc.text("ALL AUDIO LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "AUDIOS NAME",
      "DURATION",
      "STATUS",
      "BANNER",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = audioData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.banner,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredAudioData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.banner,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-AUDIO-LIST.pdf" : "AUDIO-FILTERED-LIST");
  };

  const generateXLSXAUDIO = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? audioData : filteredAudioData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-AUDIO-LIST.xlsx" : "AUDIO-FILTERED-LIST.xlsx"
    );
  };

  const handleFilterChangeAudio = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterAudio(searchTerm);
    const filteredResults = audioData.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredAudioData(filteredResults);
  };

  const closeAudioModal = () => {
    setModalCloseAudio(!modalCloseAudio);
  };

  const openModalSingleDeleteAudio = (id) => {
    setItemToDeleteAudio([id]);
    setModalCloseAudio(true);
  };

  const openModalMultiDeleteAudio = () => {
    setModalCloseAudio(true);
  };

  const handleDeleteAudioData = () => {
    closeAudioModal();
    audioDeleteOperation();
  };

  const audioDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData?.append("id", "[" + itemToDeleteAudio + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/all-library/storeAudios`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearAudio = () => {
    fetchApi();
    setFilterAudio("");
  };
  const handleAllAudio = () => {
    setSelectAllAudio(!selectAllAudio);
    setItemToDeleteAudio(
      selectAllAudio ? [] : filteredAudioData?.map((item) => item?.id)
    );
  };

  ///Audio End

  /////Episode start
  const selectRowEpisode = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteEpisode([...itemToDeleteEpisode, row?.id]);
        } else {
          setItemToDeleteEpisode(
            itemToDeleteEpisode.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteEpisode,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllEpisode}
        />
      </div>
    ),
  };

  const episodeFormatter = (data, row) => {

    const truncatedData = data
      ? (data.length > 15 ? data.slice(0, 15) + '...' : data)
      : '';

    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.Thumbnail ? row?.Thumbnail : row?.Thumbnail}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle ms-2">
          <p>{truncatedData}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <div><span className="dateText">{row?.upload_on}</span></div>

          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/episode/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterEpisode = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };


  const fileInputRefreplaceepisode = useRef(null);

  const handleClickreplaceepisode = (id) => {
    return (e) => {
      e.preventDefault();
      if (fileInputRefreplaceepisode.current) {
        fileInputRefreplaceepisode.current.click();
      } else {
        console.error('fileInputRefreplaceepisode is not assigned properly.');
      }
    };
  };


  const handleFileChangereplaceepisode = async (event, id) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData?.append('file', file);
    formData?.append('episode_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Edit-Episode-Upload`,
        formData,
        {
          headers: headers,
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };


  const handleFileChangereplaceepisodedelete = async (id) => {

    const formData = new FormData();
    // formData?.append('file', file);
    // formData?.append('video_id', id);

    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_Baseurl}/admin/episode/delete/${id}`,
        {
          headers: headers, // Ensure headers is coming from props or context
        }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);

      const errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);

      setProcessing(false);
      setShowOverlay(false);
    }
  };


  const actionFormatterEpisode = (data, row) => {
    return (
      <>
        <div className="d-flex">
          <div>
            <div onClick={handleClickreplaceepisode(row.id)} className="commonActionPadding">
              <span>
                <img src={retry_video} alt="flogo" width={20} height={20} />
              </span>
            </div>
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRefreplaceepisode}
              onChange={(e) => handleFileChangereplaceepisode(e, row.id)}
            />
          </div>
          <div>
            <div
              onClick={() => handleFileChangereplaceepisodedelete(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
            </div>
          </div>
        </div>


        {/* <div className="editdropdown">
          <span className="editdropdown-button">
            <span>
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
          </span>
          <div className="editdropdown-menu">
            <Link to={`/edit-episode/manage/${row?.id}`}>
              <span>
                <img src={edititem} alt="flogo" width={20} height={20} />
                <span className="ms-2"> Edit Episode </span>
              </span>
            </Link>

            <div
              onClick={() => openModalSingleDeleteEpisode(row?.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Delete Episode</span>
            </div>
          </div>
        </div> */}
      </>

    );
  };
  const columnsEpisode = [
    {
      dataField: "title",
      text: "Episodes",
      formatter: episodeFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterEpisode,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterEpisode,
    },
  ];
  const exportHeadersEpisode = [
    { label: "ID", key: "id" },
    { label: "EPISODE", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFEpisode = (get) => {
    const doc = new jsPDF();
    doc.text("ALL EPISODE LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "EPISODE NAME",
      "DURATION",
      "STATUS",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = episodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredEpisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-EPISODE-LIST.pdf" : "FILTERED-EPISODE-LIST");
  };

  const generateXLSXEPISODE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? episodeData : filteredEpisodeData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-EPISODE-LIST.xlsx" : "FILTERED-EPISODE-LIST.xlsx"
    );
  };

  const handleFilterChangeEpisode = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterEpisode(searchTerm);
    const filteredResults = episodeData?.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredEpisodeData(filteredResults);
  };

  const closeEpisodeModal = () => {
    setModalCloseEpisode(!modalCloseEpisode);
  };

  const openModalSingleDeleteEpisode = (id) => {
    setItemToDeleteEpisode([id]);
    setModalCloseEpisode(true);
  };

  const openModalMultiDeleteEpisode = () => {
    setModalCloseEpisode(true);
  };

  const handleDeleteEpisodeData = () => {
    closeEpisodeModal();
    episodeDeleteOperation();
  };

  const episodeDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData?.append("episode_id", "[" + itemToDeleteEpisode + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/all-library/storeVideos`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearEpisode = () => {
    fetchApi();
    setFilterEpisode("");
  };
  const handleAllEpisode = () => {
    setSelectAllEpisode(!selectAllEpisode);
    setItemToDeleteEpisode(
      selectAllEpisode ? [] : filteredEpisodeData?.map((item) => item?.id)
    );
  };

  // { Master Library data}
  // { Master Library data}



  const datadata = [
    {
      value: "add_video",
      label: "Video Upload",
      content: (
        <>
          <div>
            <Dropzone accept="video/*" onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps({ accept: 'video/*' })} id="add_video_upload" />
                  <p className="draganddrop">Drag and drop video files here, or click to select files</p>
                </div>
              )}
            </Dropzone>

            <div className="text-center video-overall-progress-li">
              {loading && (
                <div>
                  <p>Uploading...{overallProgress().toFixed(2)}%</p>
                  <progress value={overallProgress()} max="100" />
                  {Object.keys(progress).map((fileName, index) => (
                    <div key={index}>

                      <div className="d-flex align-item-center justify-content-center">
                        <div>
                          <p className="d-flex">{fileName} - {progress[fileName]}% </p>
                        </div>
                        <div className="ms-2"> <img onClick={() => cancelUpload(fileName)} src={cancel_video} className="w-100" height={15} style={{ cursor: "pointer" }} />
                        </div>
                      </div>


                      {/* <p className="d-flex">{fileName} - {progress[fileName]}% <img onClick={() => cancelUpload(fileName)} src={cancel_video} className="w-100" width={15} height={15} /></p> */}
                    </div>
                  ))}
                </div>
              )}

              {uploadedFiles?.length > 0 && !loading && (
                <div className="text-center">
                  <p>Uploaded Files:</p>
                  <ul>
                    {uploadedFiles.map((file, index) => (
                      <li key={index}>
                        {file?.name} - {file?.size} MB
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {failedFiles?.length > 0 && (
                <div className="text-center">
                  <p>Failed Files:</p>
                  <ul>
                    {failedFiles?.map((file, index) => (
                      <li key={index}>
                        <div className="d-flex align-item-center justify-content-center">
                          <div>
                            <p className="d-flex"> {file?.name} </p>
                          </div>
                          <div className="ms-2"> <img onClick={() => retryUpload(file)} src={retry_video} className="w-100" height={15} style={{ cursor: "pointer" }} />

                          </div>
                        </div>

                        {/* {file?.name}
                      <img onClick={() => retryUpload(file)} src={retry_video} className="w-100" height={15} style={{ cursor: "pointer" }} /> */}
                        {/* <button onClick={() => retryUpload(file)}>Retry</button> */}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {uploadedFiles.length > 0 && !loading && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNextNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              )}
            </div>
          </div>


          {/* LIBRARY DATA ALL  */}
          {/* LIBRARY DATA ALL  */}
          <div className="mt-3">
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdroplibrarylibrary" >  Choose from Library</button>
          </div>

          <div className="modal fade" id="staticBackdroplibrarylibrary" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">All Library Lists</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {/* video modal */}
                  <div
                    className={`modal packageModel ${modelClose ? "show" : ""}`}
                    tabIndex="-1"
                    style={{ display: `${modelClose ? "block" : "none"}` }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm Selected</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={closeModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          {itemToDeleteVideo?.length === 1 ? (
                            <p> Are you sure you want to Add this item ?</p>
                          ) : (
                            <p className="fs-6">
                              Are you sure you want to Add Selected the items ?
                            </p>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeModal}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleConfirmDeletevideo}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* live modal */}
                  <div
                    className={`modal  packageModel ${modalCloseLive ? "show" : ""}`}
                    tabIndex="-1"
                    style={{ display: modalCloseLive ? "block" : "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm Selected</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={closeLiveModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          {itemToDeleteLive?.length === 1 ? (
                            <p> Are you sure you want to Add this item ?</p>
                          ) : (
                            <p className="fs-6">
                              Are you sure you want to Add Selected the items ?
                            </p>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeLiveModal}
                          >
                            Close
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleDeleteLiveData}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* audio modal */}
                  <div
                    className={`modal  packageModel ${modalCloseAudio ? "show" : ""}`}
                    tabIndex="-1"
                    style={{ display: modalCloseAudio ? "block" : "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm Selected</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={closeAudioModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          {itemToDeleteAudio?.length === 1 ? (
                            <p> Are you sure you want to Add this item ?</p>
                          ) : (
                            <p className="fs-6">
                              Are you sure you want to Add Selected the items ?
                            </p>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeAudioModal}
                          >
                            Close
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleDeleteAudioData}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* episode modal */}
                  <div
                    className={`modal  packageModel ${modalCloseEpisode ? "show" : ""}`}
                    tabIndex="-1"
                    style={{ display: modalCloseEpisode ? "block" : "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm Selected</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={closeEpisodeModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          {itemToDeleteEpisode?.length === 1 ? (
                            <p> Are you sure you want to Add this item ?</p>
                          ) : (
                            <p className="fs-6">
                              Are you sure you want to Add Selected the items ?
                            </p>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeEpisodeModal}
                          >
                            Close
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleDeleteEpisodeData}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <section className="section Master container-fluid">
                    <div className="row">
                      <div className="col-sm-12">

                        <div className="iq-card-body table-responsive ">
                          <div className="table-view">
                            <ul
                              className="nav nav-pills justify-content-center row"
                              id="pills-tab "
                              role="tablist"
                            >
                              <li
                                className="nav-item col-12 col-sm-6 col-md-3 "
                                role="presentation"
                              >
                                <button
                                  className="nav-link active fs-6 fw-bold px-4 w-100  mx-auto "
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  Videos
                                </button>
                              </li>
                              {/* <li
                                    className="nav-item col-12 col-sm-6 col-md-3 "
                                    role="presentation"
                                  >
                                    <button
                                      className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Live Stream Videos
                                    </button>
                                  </li> */}
                              <li
                                className="nav-item col-6 col-sm-6 col-md-3 "
                                role="presentation"
                              >
                                <button
                                  className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                  id="pills-episode-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-episode"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-contact"
                                  aria-selected="false"
                                >
                                  Episode
                                </button>
                              </li>
                              <li
                                className="nav-item col-6 col-sm-6 col-md-3 "
                                role="presentation"
                              >
                                <button
                                  className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                  id="pills-contact-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-contact"
                                  aria-selected="false"
                                >
                                  Audios
                                </button>
                              </li>

                            </ul>
                            <hr></hr>
                            <div className="tab-content p-0" id="pills-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                              >
                                <div className=" m-0 p-0">
                                  <div className="container-fluid">
                                    <section className="section all-video-stream ">
                                      <div className="col-lg-12 ">
                                        <div className="iq-card shadow-none p-0">
                                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                            <div className="filterContainer">
                                              <input
                                                type="text"
                                                placeholder="Filter all.."
                                                className="filter form-control "
                                                value={filterVideo}
                                                onChange={handleFilterChange}
                                              />
                                              {filterVideo?.length > 0 && (
                                                <button
                                                  type="button"
                                                  className="closeBtn"
                                                  onClick={handleClear}
                                                >
                                                  <i className="fa fa-times"></i>
                                                </button>
                                              )}
                                            </div>
                                            <div className="btn-group flex-wrap justify-content-between justify-content-sm-end ">
                                              {/* {itemToDeleteVideo?.length > 0 && (
                                                <button
                                                  className="btn btn-primary d-flex d-inline-flex  fw-bolder  dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                  onClick={openModal}
                                                >
                                                  Move Library to Episode
                                                </button>
                                              )} */}
                                              {/* <div
                                                  className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                                  key={"2"}
                                                >
                                                  <button
                                                    className="btn btn-success dropdown-toggle d-flex mx-0"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <span className="fw-bolder">Export </span>
                                                    <img
                                                      src={ExportIcon}
                                                      className="ms-2 exportIcon"
                                                      alt="exportIcon"
                                                    />
                                                  </button>
                                                  <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li className="dropdown-item">
                                                      <CSVLink
                                                        headers={exportHeaders}
                                                        data={filteredVideoData}
                                                        className="text-dark d-block"
                                                        filename="VIDEO-LIST.csv"
                                                      >
                                                        CSV
                                                      </CSVLink>
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        conditionalGeneratePDF(0);
                                                      }}
                                                    >
                                                      PDF
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        generateXLSX(0);
                                                      }}
                                                    >
                                                      XLSX
                                                    </li>
                                                  </ul>
                                                </div> */}

                                              {/* <div className="dropdown d-block mx-0">
                                                  <button
                                                    className="btn btn-success dropdown-toggle d-flex ms-2"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <span className="fw-bolder">
                                                      Export All
                                                    </span>
                                                    <img
                                                      src={ExportIcon}
                                                      className="ms-2 exportIcon"
                                                      alt="exportAllIcon"
                                                    />
                                                  </button>
                                                  <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => setPage(0)}
                                                    >
                                                      <CSVLink
                                                        headers={exportHeaders}
                                                        data={videoData}
                                                        className="text-dark d-block"
                                                        filename="VIDEO-LIST.csv"
                                                      >
                                                        CSV
                                                      </CSVLink>
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        conditionalGeneratePDF(1);
                                                      }}
                                                    >
                                                      PDF
                                                    </li>
                                                    <li
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        generateXLSX(1);
                                                      }}
                                                    >
                                                      XLSX
                                                    </li>
                                                  </ul>
                                                </div> */}
                                            </div>
                                          </div>
                                          <div className="bootstrapTable">
                                            <BootstrapTable
                                              keyField="id"
                                              columns={columns}
                                              data={filteredVideoData}
                                              pagination={commonPaginationConfig(
                                                videoSizePerPageList
                                              )}
                                              className="no-border-table"
                                              noDataIndication={
                                                <div className="noRecord">
                                                  {loading ? (
                                                    <label>
                                                      {" "}
                                                      <Response_Loading_processing></Response_Loading_processing>
                                                      Loading...
                                                    </label>
                                                  ) : (
                                                    filteredVideoData?.length <= 0 && (
                                                      <p>No Video Found!</p>
                                                    )
                                                  )}
                                                </div>
                                              }
                                              hover
                                              headerClasses="videoWidth"
                                              selectRow={selectRow}
                                              selectAll={selectAllVideo}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>

                              {/* <div
                                  className="tab-pane fade"
                                  id="pills-profile"
                                  role="tabpanel"
                                  aria-labelledby="pills-profile-tab"
                                >
                                  <section className="section container-fluid ">
                                    <div id="content-page" className="content-page">
                                      <div className="iq-card  shadow-none p-0">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                          <div className="filterContainer">
                                            <input
                                              type="text"
                                              placeholder="Filter all.."
                                              className="filter form-control "
                                              value={filterLive}
                                              onChange={handleFilterChangeLive}
                                            />
                                            {filterLive?.length > 0 && (
                                              <button
                                                type="button"
                                                className="closeBtn"
                                                onClick={handleClearLive}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            )}
                                          </div>
                                          <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                                            {itemToDeleteLive?.length > 0 && (
                                              <button
                                                className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle  mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                onClick={openModalMultiDeleteLive}
                                              >
                                                <MdDelete className="exportIcon " />
                                                Delete
                                              </button>
                                            )}
                                            <div
                                              className="dropdown d-block  mb-2 mb-sm-0 mx-0 "
                                              key={"2"}
                                            >
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex mx-0"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export </span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersLive}
                                                    data={filteredLiveData}
                                                    className="text-dark d-block"
                                                    filename="LIVE-FILTERED-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFLive(0);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXLIVE(0);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div>

                                            <div className="dropdown d-block ">
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex ms-2"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export All</span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersLive}
                                                    data={liveData}
                                                    className="text-dark d-block"
                                                    filename="ALL-LIVES-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFLive(1);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXLIVE(1);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bootstrapTable">
                                          <BootstrapTable
                                            keyField="id"
                                            columns={liveColumns}
                                            data={filteredLiveData}
                                            pagination={commonPaginationConfig(
                                              liveSizePerPageList
                                            )}
                                            className="no-border-table"
                                            noDataIndication={
                                              <div className="noRecord">
                                                {loading ? (
                                                  <label>
                                                    {" "}
                                                    <Response_Loading_processing></Response_Loading_processing>
                                                    Loading...
                                                  </label>
                                                ) : (
                                                  filteredLiveData?.length <= 0 && (
                                                    <p>No Live Streams Available. !!!</p>
                                                  )
                                                )}
                                              </div>
                                            }
                                            hover
                                            headerClasses="videoWidth"
                                            selectRow={selectRowLive}
                                            selectAll={selectAllLive}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div> */}
                              <div
                                className="tab-pane fade"
                                id="pills-contact"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                              >
                                <section className="section container-fluid ">
                                  <div id="content-page" className="content-page">
                                    <div className="iq-card shadow-none p-0">
                                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                        <div className="filterContainer">
                                          <input
                                            type="text"
                                            placeholder="Filter all.."
                                            className="filter form-control "
                                            value={filterAudio}
                                            onChange={handleFilterChangeAudio}
                                          />
                                          {filterAudio?.length > 0 && (
                                            <button
                                              type="button"
                                              className="closeBtn"
                                              onClick={handleClearAudio}
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          )}
                                        </div>
                                        <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                                          {/* {itemToDeleteAudio?.length > 0 && (
                                              <button
                                                className="btn btn-primary d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                                onClick={openModalMultiDeleteAudio}
                                              >
                                                <MdDelete className="exportIcon " />
                                                Save Library
                                              </button>
                                            )} */}
                                          {/* <div
                                              className="dropdown d-block mb-2 mb-sm-0 mx-0  "
                                              key={"2"}
                                            >
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex mx-0"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export </span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersAudio}
                                                    data={filteredAudioData}
                                                    className="text-dark d-block"
                                                    filename="AUDIO-FILTERED-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFAudio(0);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXAUDIO(0);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}

                                          {/* <div className="dropdown d-block ">
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex ms-2"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export All</span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersAudio}
                                                    data={audioData}
                                                    className="text-dark d-block"
                                                    filename="ALL-AUDIO-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFAudio(1);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXAUDIO(1);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}
                                        </div>
                                      </div>
                                      <div className="bootstrapTable">
                                        <BootstrapTable
                                          keyField="id"
                                          columns={columnsAudio}
                                          data={filteredAudioData}
                                          pagination={commonPaginationConfig(
                                            audioSizePerPageList
                                          )}
                                          className="no-border-table"
                                          noDataIndication={
                                            <div className="noRecord">
                                              {loading ? (
                                                <label>
                                                  {" "}
                                                  <Response_Loading_processing></Response_Loading_processing>
                                                  Loading...
                                                </label>
                                              ) : (
                                                filteredAudioData?.length <= 0 && (
                                                  <p>No Audios Available !!!</p>
                                                )
                                              )}
                                            </div>
                                          }
                                          hover
                                          headerClasses="videoWidth"
                                          selectRow={selectRowAudio}
                                          selectAll={selectAllAudio}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="pills-episode"
                                role="tabpanel"
                                aria-labelledby="pills-episode-tab"
                              >
                                <section className="section container-fluid">
                                  <div id="content-page" className="content-page">
                                    <div className="iq-card shadow-none p-0">
                                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                        <div className="filterContainer">
                                          <input
                                            type="text"
                                            placeholder="Filter all.."
                                            className="filter form-control "
                                            value={filterEpisode}
                                            onChange={handleFilterChangeEpisode}
                                          />
                                          {filterEpisode?.length > 0 && (
                                            <button
                                              type="button"
                                              className="closeBtn"
                                              onClick={handleClearEpisode}
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          )}
                                        </div>
                                        <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                                          {itemToDeleteEpisode?.length > 0 && (
                                            <button
                                              className="btn btn-primary d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                              onClick={openModalMultiDeleteEpisode}
                                            >
                                              {/* <MdDelete className="exportIcon " /> */}
                                              Move Library to Video
                                            </button>
                                          )}
                                          {/* <div
                                              className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                              key={"2"}
                                            >
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex mx-0 "
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export </span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersEpisode}
                                                    data={filteredEpisodeData}
                                                    className="text-dark d-block"
                                                    filename="FILTERED-EPISODE-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFEpisode(0);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXEPISODE(0);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}

                                          {/* <div className="dropdown d-block ">
                                              <button
                                                className="btn btn-success dropdown-toggle d-flex ms-2"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span className="fw-bolder">Export All</span>
                                                <img
                                                  src={ExportIcon}
                                                  alt="image_all"
                                                  className="ms-2 exportIcon"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                <li className="dropdown-item">
                                                  <CSVLink
                                                    headers={exportHeadersEpisode}
                                                    data={episodeData}
                                                    className="text-dark d-block"
                                                    filename="ALL-EPISODE-LIST.csv"
                                                  >
                                                    CSV
                                                  </CSVLink>
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    conditionalGeneratePDFEpisode(1);
                                                  }}
                                                >
                                                  PDF
                                                </li>
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    generateXLSXEPISODE(1);
                                                  }}
                                                >
                                                  XLSX
                                                </li>
                                              </ul>
                                            </div> */}
                                        </div>
                                      </div>
                                      <div className="bootstrapTable">
                                        <BootstrapTable
                                          keyField="id"
                                          columns={columnsEpisode}
                                          data={filteredEpisodeData}
                                          pagination={commonPaginationConfig(
                                            episodeSizePerPageList
                                          )}
                                          className="no-border-table"
                                          noDataIndication={
                                            <div className="noRecord">
                                              {loading ? (
                                                <label>
                                                  {" "}
                                                  <Response_Loading_processing></Response_Loading_processing>
                                                  Loading...
                                                </label>
                                              ) : (
                                                filteredEpisodeData?.length <= 0 && (
                                                  <p>No Episode Available !!!</p>
                                                )
                                              )}
                                            </div>
                                          }
                                          hover
                                          headerClasses="videoWidth"
                                          selectRow={selectRowEpisode}
                                          selectAll={selectAllEpisode}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {/* <div className="modal-footer">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      {itemToDeleteVideo?.length > 0 && (
                        <button
                          className="btn btn-primary d-flex d-inline-flex  fw-bolder  dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                          onClick={openModalvideo}
                        >
                          Save Video Library
                        </button>
                      )}
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
          {/* LIBRARY DATA ALL  */}
          {/* LIBRARY DATA ALL  */}

        </>
      ),
    },
    {
      value: "m3u8_url",
      label: "M3u8 Url",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1"> m3u8 URL: </label>
                <input
                  type="text"
                  className="form-control"
                  name="m3u8_trailer"
                  id="m3u8_trailer"
                  onChange={(e) => setM3u8_url(e.target.value)}
                  placeholder="m3u8 Trailer"
                />
              </div>
            </div>

            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNextNext();
                    }}
                    className="btn btn-primary ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "mp4_url",
      label: "Video Mp4",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1"> Mp4 File URL: </label>
                <input
                  type="text"
                  className="form-control"
                  name="live_mp3_url"
                  id="live_mp3_url"
                  placeholder="Mp4 File URL"
                  onChange={(e) => setMp4_url(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNextNext();
                    }}
                    className="btn btn-primary ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "embed",
      label: "Embed Code",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1"> Embed URL: </label>
                <div>
                  <p>
                    Example URL Format : (
                    Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                  </p>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="embed_trailer"
                  id="embed_trailer"
                  placeholder="Embed Trailer"
                  onChange={(e) => setEmbed_code(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <div className="">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                }}
                className="btn btn-primary"
              >
                Submit
              </Button>
              {uploadCompleteddata && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNextNext();
                  }}
                  className="btn btn-primary ms-3"
                >
                  Proceed to Next
                </Button>
              )}{" "}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [selectedOption, setSelectedOption] = useState(datadata[0].value);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [inputValuedata, setInputValuedata] = useState("");

  const handleInputChangedata = (event) => {
    const newValue = event.target.value;

    // Validate input using a regular expression
    const validInput =
      /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)([eE][-+]?\d+)?[a-zA-Z]*$/.test(newValue);

    if (validInput) {
      setInputValue(newValue);
    }
  };

  const steps = [
    {
      label: "Add New Video",
      description: (
        <div>
          <div className="mt-3">
            <h4>Upload Video</h4>
          </div>
          <div className="card mt-4 p-3">
            <div className="radio-options text-center row d-flex py-3">
              {datadata?.map((option) => (
                <div
                  key={option?.value}
                  className={` col-lg-3 radio-option ${selectedOption === option?.value ? "active" : ""
                    }`}
                >
                  <input
                    type="radio"
                    value={option?.value}
                    id={option?.value}
                    checked={selectedOption === option?.value}
                    onChange={handleOptionChange}
                  />
                  <label className="ms-1">{option?.label}</label>
                </div>
              ))}
            </div>

            <div className="active-div-content">
              {
                datadata?.find((option) => option?.value === selectedOption)
                  ?.content
              }
            </div>
          </div>

          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="">
          <section className="">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <h3>
                  {label_management?.videos[74]?.title}</h3>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="text-end">
                  <button onClick={videoupload} className="btn btn-primary">
                    {label_management?.videos[72]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Video</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      {label_management?.videos[0]?.title}<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      {label_management?.videos[0]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        onBlur={handleBlurtitle}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.videos[1]?.title}</label>
                    <p className="t-1">{label_management?.videos[1]?.sub_title}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.videos[2]?.title}</label>
                    <p className="t-1">{label_management?.videos[2]?.sub_title}</p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.videos[3]?.title}</label>
                      <p className="t-1">{label_management?.videos[3]?.sub_title}</p>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={details}
                          id="details"
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.videos[4]?.title}</label>
                      <p className="t-1">{label_management?.videos[4]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          id="duration"
                          maxLength="8"
                          className="rs-input form-control-lg"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                          placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Free Duration Time</label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="free_duration_time"
                          className="rs-input form-control-lg"
                          maxLength="8"
                          placeholder="HH:MM:SS"
                          value={editUser?.free_duration_time}
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.videos[5]?.title}</label>
                      <p className="t-1">{label_management?.videos[5]?.sub_title}</p>

                      <div className="mt-2">
                        <input
                          type="date"
                          name="year"
                          id="year"
                          value={editUser?.year}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                          ref={yearInputRef}
                        />
                      </div>

                      <span className="errorred">
                        {!isValidationHiddenyear && (
                          <p>{validationMessageyear}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>{label_management?.videos[6]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.videos[7]?.title}</label>
                      <p className="t-1">{label_management?.videos[7]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">{label_management?.videos[8]?.title}</label>
                      <p className="t-1">{label_management?.videos[8]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={(e) => setAge_restrict(e.target.value)}
                        >
                          <option selected value="">
                            Choose an Age
                          </option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.age}>{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="mt-2 text-start">
                      <label className="m-0">Album</label>
                      <p className="t-1">Select A Audio Album Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          onChange={(e) => setAlbum_id(e.target.value)}
                        >
                          <option value="">Choose a Album</option>
                          {album?.map((item, key) => (
                            <option value={item?.id}>{item?.albumname}</option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[9]?.title}</label>
                      <p className="t-1">{label_management?.videos[9]?.sub_title}</p>
                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className=""
                          id="artists"
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[10]?.title}</label>
                      <p className="t-1">{label_management?.videos[10]?.sub_title}</p>
                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          id="category"
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[11]?.title}</label>
                      <p className="t-1">{label_management?.videos[11]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          id="language"
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguage && (
                          <p>{validationMessagelanguage}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[12]?.title}</label>
                      <p className="t-1">{label_management?.videos[12]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={countryblock_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          id="countryblock_country"
                          isMulti
                          value={selectedOptionsCountryblock_country}
                          onChange={handleSelectChangeCountryblock_country}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[13]?.title}</label>
                      <p className="t-1">{label_management?.videos[13]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          id="available_country"
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>

                    {/* <div className=" text-start mt-2">
                      <label className="m-0">Available Country</label>
                      <p className="t-1">
                        Available the Video for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div> */}
                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[14]?.title}</label>
                      <p className="t-1">{label_management?.videos[14]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={related_videos?.map((option) => ({
                            value: option?.id,
                            label: option?.title,
                          }))}
                          className=""
                          id="related_videos"
                          isMulti
                          value={selectedOptionsRelated_videos}
                          onChange={handleSelectChangeRelated_videos}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[15]?.title}</label>
                      <p className="t-1">{label_management?.videos[15]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={video_playlist?.map((option) => ({
                            value: option?.id,
                            label: option?.title,
                          }))}
                          className=""
                          id="video_playlist"
                          isMulti
                          value={selectedOptionsvideo_playlist}
                          onChange={handleSelectChangevideo_playlist}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>
                      {label_management?.videos[16]?.title} <small>{label_management?.videos[61]?.title}</small>
                    </h5>
                    <hr></hr>

                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[17]?.title}
                            <small>  {label_management?.videos[17]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="skip_start_time"
                              id="skip_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[18]?.title}
                            <small>  {label_management?.videos[18]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.recap_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[19]?.title}
                            <small>  {label_management?.videos[19]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="skip_end_time"
                              id="skip_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_end_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[20]?.title}
                            <small>  {label_management?.videos[20]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[21]?.title}
                            <small>  {label_management?.videos[21]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.skip_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="skip_start_session"
                              id="skip_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_session}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            {label_management?.videos[22]?.title}
                            <small>  {label_management?.videos[22]?.sub_title}</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_start_session"
                              id="recap_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_session}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[23]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">{label_management?.videos[24]?.title}</label>
                    <p className="t-1">{label_management?.videos[24]?.sub_title}</p>

                    <div className="mt-2">
                      <div className="">
                        <div className="">
                          <input
                            type="file"
                            className="form-control-lg rs-input"
                            name="pdf_file"
                            id="pdf_file"
                            accept="application/pdf"
                            onChange={(e) => setPdf_files(e.target.files[0])}
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[25]?.title}</label>
                    <p className="t-1">{label_management?.videos[25]?.sub_title}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_link"
                        onChange={(e) => setUrl_link(e.target.value)}
                        id="url_link"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-6">
                      <div className=" text-start mt-2">
                        <label className="m-0">{label_management?.videos[26]?.title}</label>
                        <p className="t-1">{label_management?.videos[26]?.sub_title}</p>
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control-lg rs-input"
                            name="url_linktym"
                            maxLength={8}
                            placeholder="HH:MM:SS"
                            value={userTime?.url_linktym}
                            onChange={handleInputintrotime}
                            id="url_linktym"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className=" text-start mt-2">
                        <label className="m-0">{label_management?.videos[27]?.title}</label>
                        <p className="t-1">{label_management?.videos[27]?.sub_title}</p>
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control-lg rs-input"
                            name="urlEnd_linksec"
                            maxLength={8}
                            placeholder="HH:MM:SS"
                            value={userTime?.urlEnd_linksec}
                            onChange={handleInputintrotime}
                            id="urlEnd_linksec"
                          />
                        </div>
                      </div>
                    </div>
                  </div>



                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[28]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.videos[29]?.title}</label>
                    <p className="t-1">{label_management?.videos[29]?.sub_title}</p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsitetitle(e.target.value)}
                        placeholder=""
                        id="website_page_title"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.videos[30]?.title}</label>
                    <p className="t-1">{label_management?.videos[30]?.sub_title}</p>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsiteurl(e.target.value)}
                        placeholder=""
                        id="website_url"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.videos[31]?.title}</label>
                    <p className="t-1">{label_management?.videos[31]?.sub_title}</p>
                    <div className=" mt-2">
                      <textarea
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setMetadescription(e.target.value)}
                        placeholder=""
                        id="meta_description"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[32]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.videos[33]?.title}</label>
                    <p className="p2">{label_management?.videos[34]?.title}</p>

                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input"
                        onChange={handleTagsChange}
                        name="search_tags"
                        id="search_tags"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={videoupload} className="btn btn-primary">
                    {label_management?.videos[72]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>{label_management?.videos[43]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[44]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[44]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              id="video_thumbnail"
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[45]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[45]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              id="player_thumbnail"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[46]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[46]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              id="video_tv_thumbnail"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(3)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[47]?.title}
                      {compressResolutionFormat?.videos === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.videos[47]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              id="video_title_thumbnail"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4,
                                  'file4'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile4)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(4)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file4 && <div className="errorred mt-1">{error?.file4}</div>}
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[51]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">{label_management?.videos[52]?.title}</label>
                    <p className="t-1">{label_management?.videos[52]?.sub_title}</p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-12 col-sm-12 col-lg-12">
                          <select
                            className="form-select"
                            name="trailer_type"
                            id="trailer_type"
                            onChange={(e) => setTrailer_type(e.target.value)}
                            onClick={(event) => {
                              setEnable_restreamtrailer(event.target.value);
                            }}
                          >
                            <option value="null">
                              {" "}
                              Select the Video Trailer Type{" "}
                            </option>
                            <option value="1">Video Upload </option>
                            <option value="m3u8_url">M3u8 Url </option>
                            <option value="mp4_url">Mp4 Url</option>
                            <option value="embed_url">Embed Code</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start">
                    <div
                      className=""
                      onClick={(event) => {
                        setEnablestreamvisibletrailer(event.target.value);
                      }}
                    >
                      {proceednexttrailer()}
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.videos[53]?.title}</label>
                    <p className="t-1">{label_management?.videos[53]?.sub_title}</p>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={trailer_description}
                        id="trailer_description"
                        onChange={(newContent) =>
                          contentFieldChanagedtraildes(newContent)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.videos[54]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="mb-2">{label_management?.videos[55]?.title}</label>
                    <div>
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        value="publish_now"
                        checked={publishType === "publish_now"}
                        onChange={handlePublishTypeChange}
                      />
                      <label htmlFor="publish_now" className="mt-2 ms-1">
                        Publish Now
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="publish_later"
                        name="publish_type"
                        value="publish_later"
                        checked={publishType === "publish_later"}
                        onChange={handlePublishTypeChange}
                      />
                      <label htmlFor="publish_later" className="mt-2 ms-1">
                        {label_management?.videos[57]?.title}
                      </label>
                      {publishType === "publish_later" && (
                        <div className="mb-3">
                          <label className="m-0">  {label_management?.videos[64]?.title}
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="publish_time"
                            name="publish_time"
                            value={publishTime}
                            onChange={handlePublishTimeChange}
                          />
                        </div>
                      )}
                    </div>
                    {/* <button onClick={handleFormSubmit}>Submit</button> */}
                  </div>

                  {/* <div className="col-sm-12">
                    <label className="mb-2">Publish Type</label>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                        value="publish_now"
                        checked="checked"
                      />{" "}
                      <span
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Now
                      </span>
                    </div>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_later"
                        value="publish_later"
                        name="publish_type"
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      />{" "}
                      <span
                        value="publish_later"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Later
                      </span>{" "}
                    </div>
                  </div>

                  <div className="col-sm-12" id="publishlater">
                    <div
                      className="mt-2"
                      onClick={(event) => {
                        setEnablestreamvisiblepublic(event.target.value);
                      }}
                    >
                      {proceednextpublic()}
                    </div>
                  </div> */}
                </div>
                <div className="card p-3">
                  <h5>{label_management?.videos[40]?.title}</h5>
                  <hr />

                  <div className="col-sm-12">
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.videos[41]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="free_duration_status"
                            id="free_duration_status"
                            onChange={handleInputenabletime}
                            defaultChecked={editUser?.free_duration_status === 1}
                            checked={editUser?.free_duration_status === 1}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="free_duration_status"
                            name="free_duration_status"
                            value={editUser?.free_duration_status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">{label_management?.videos[42]?.title}</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="free_duration_time"
                            id="free_duration_time"
                            className="rs-input form-control-lg"
                            value={editUser?.free_duration_time}
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            onChange={handleInputdatatime}
                            ref={free_duration_timeInputRef}
                          />
                          {!isValidationHiddenfree_duration_time && (
                            <div className="mandatory bg-danger">
                              {validationMessagefree_duration_time}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>{label_management?.videos[58]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0">{label_management?.videos[59]?.title}</label>
                      <p className="p1">{label_management?.videos[59]?.sub_title}</p>
                      <select
                        onChange={(e) => handleAccess(e.target.value)}
                        className="form-select"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                        ref={accessInputRef}
                      >
                        <option value="">Select User Access</option>
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                      <span className="errorred mt-2 d-block">
                        {!isValidationHiddenAccess && (
                          <p>{validationMessageAccess}</p>
                        )}
                      </span>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.videos[35]?.title}</h5>
                    <hr></hr>
                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.videos[36]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            id="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              featured?.featured == 1 ? true : false
                            }
                            checked={featured?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.videos[37]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="active"
                              id="active"
                              onChange={handleInputenable}
                              defaultChecked={editUser?.active === 1 ? true : false}
                              checked={editUser?.active === 1 ? true : false}
                              className="rs-input"
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="active"
                              id="active"
                              onChange={handleInputenable}
                              value={editUser?.active === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.videos[38]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            id="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={banner?.banner == 1 ? true : false}
                            checked={banner?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.videos[39]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_video_title_image"
                            id="enable_video_title_image"
                            onChange={handleInput}
                            defaultChecked={
                              enable_video_title_image?.enable_video_title_image ==
                                1
                                ? true
                                : false
                            }
                            checked={
                              enable_video_title_image?.enable_video_title_image ==
                                1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="enable_video_title_image"
                            onChange={handleInput}
                            value={
                              enable_video_title_image?.enable_video_title_image ==
                                1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Get Pricing from Global PPV Rates</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="global_ppv"
                            id="global_ppv"
                            onChange={handleInput}
                            defaultChecked={
                              global_ppv?.global_ppv == 1 ? true : false
                            }
                            checked={global_ppv?.global_ppv == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="global_ppv"
                            name="global_ppv"
                            onChange={handleInput}
                            value={global_ppv?.global_ppv == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className="card p-3">

                    <h5>{label_management?.videos[48]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.videos[49]?.title}</label>
                      <p className="t-1">{label_management?.videos[49]?.sub_title}</p>
                      <div className="mt-2 file-draganddrop">
                        {/* <input
                          type="file"
                          className="form-control-lg rs-input"
                          name="reels_videos"
                          accept="video/mp4,video/x-m4v,video/*"
                          multiple 
                          onChange={handleDropreels}
                          // multiple
                        /> */}

                        <Dropzone
                          onDrop={handleDropreels}
                          accept="video/*"
                        // multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone">
                              <input {...getInputProps()} id="reels_upload" />
                              <p className="p-2">
                                Drag and drop reels videos here
                              </p>
                              {videosreels.length > 0 && (
                                <p>
                                  {videosreels.length}{" "}
                                  {videosreels.length === 1 ? "file" : "files"}{" "}
                                  selected
                                </p>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.videos[50]?.title}
                        {compressResolutionFormat?.videos === 1 && (
                          <span className="mandatory">*</span>
                        )}
                      </label>
                      <p className="t-1">
                        {label_management?.videos[50]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div
                              className="imagedrop"
                              onClick={() => fileInputRef5.current.click()}
                            >
                              <input
                                type="file"
                                id="reels_video_thumbnail"
                                ref={fileInputRef5}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef5,
                                    setSelectedFile5,
                                    'file5'
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label>
                                <CameraRetroIcon />
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div>
                              {selectedFile5 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL.createObjectURL(selectedFile5)}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() => handleDelete(5)}
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {error?.file5 && <div className="errorred mt-1">{error?.file5}</div>}
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>
                      {label_management?.videos[60]?.title} <small>{label_management?.videos[62]?.title}</small>
                    </h5>
                    <hr></hr>
                    <div className="mt-0">
                      {Subtitlesmap?.map((item, index) => (
                        <div className="col-sm-12 mt-2" key={index}>
                          <label>{item?.language}</label>
                          <div className="file-draganddrop mt-2">
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                handleDropsubtitles(
                                  item?.short_code,
                                  acceptedFiles
                                )
                              }
                              accept="video/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="dropzone">
                                  <input {...getInputProps()} id="subtitles" />
                                  <p className="p-3">
                                    Drag and drop subtitles here
                                  </p>
                                  {videosubtitles[item?.short_code]?.length >
                                    0 && (
                                      <p>
                                        {videosubtitles[item?.short_code].length}{" "}
                                        {videosubtitles[item?.short_code]
                                          .length === 1
                                          ? "file"
                                          : "files"}{" "}
                                        selected
                                      </p>
                                    )}
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* <div className="card p-3">
                    <h5>
                      Choose Ads Settings <small>(Optional)</small>
                    </h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* <div className="mt-3">
            <h4>Video Information:</h4>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-sm-6 form-group">
                  <label className="m-0">Title :</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Video Title"
                    name="title"
                    id="title"
                    value={editUser?.title}
                    onChange={handleInput}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label className="m-0">Video Slug :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    id="slug"
                    placeholder="Video Slug"
                    onChange={(e) => setSlug(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="m-0">Age Restrict :</label>
                  <select
                    className="form-control"
                    id="age_restrict"
                    name="age_restrict"
                    onChange={(e) => setAge_restrict(e.target.value)}
                  >
                    {age_restrictdata.map((item) => (
                      <option selected value={item?.id}>
                        {item?.slug}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 form-group ">
                  <label className="m-0">Rating:</label>
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) => setRating(e.target.value)}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 form-group">
                  <label className="m-0">Video Description:</label>
                  <JoditEditor
                    value={descript}
                    onChange={(newContent) =>
                      contentFieldChanageddescript(newContent)
                    }
                  />
                </div>
                <div className="col-12 form-group">
                  <label className="m-0">Links &amp; Details:</label>
                  <JoditEditor
                    value={detal}
                    onChange={(newContent) => contentFieldChanaged(newContent)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Skip Intro Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="skip_intro"
                    name="skip_intro"
                    onChange={(e) => setSkip_intro(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Intro Start Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="intro_start_time"
                    name="intro_start_time"
                    onChange={(e) => setIntro_start_time(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Intro End Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="intro_end_time"
                    name="intro_end_time"
                    onChange={(e) => setIntro_end_time(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Skip Recap <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="skip_recap"
                    name="skip_recap"
                    onChange={(e) => setSkip_recap(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Recap Start Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recap_start_time"
                    name="recap_start_time"
                    onChange={(e) => setRecap_start_time(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Recap End Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recap_end_time"
                    name="recap_end_time"
                    onChange={(e) => setRecap_end_time(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="m-0">Video Duration:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Video Duration"
                    name="duration"
                    id="duration"
                    value={editUser?.duration}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label className="m-0">Year:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Release Year"
                    name="year"
                    id="year"
                    onChange={(e) => setYear(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="mb-2">Publish Type</label>
                  <div>
                    <input
                      type="radio"
                      id="publish_now"
                      name="publish_type"
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                      onChange={(e) => setPublish_type(e.target.value)}
                      value="publish_now"
                      checked="checked"
                    />{" "}
                    <span>Publish Now</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="publish_later"
                      value="publish_later"
                      name="publish_type"
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                      onChange={(e) => setPublish_type(e.target.value)}
                    />{" "}
                    <span
                      value="publish_later"
                      name="publish_type"
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                      onChange={(e) => setPublish_type(e.target.value)}
                    >
                      Publish Later
                    </span>{" "}
                  </div>
                </div>

                <div className="col-sm-6 form-group" id="publishlater">
                  <div
                    onClick={(event) => {
                      setEnablestreamvisiblepublic(event.target.value);
                    }}
                  >
                    {proceednextpublic()}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-3">
              <Button onClick={handleBack} className="btn btn-dark ms-2">
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNextNext}
                className="btn btn-primary ms-2"
              >
                Next
              </Button>
            </div>
          </div> */}
        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <>
          {/* <div className="container-fluid">
          <div className="form-card mt-3">
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Select Video Category :</label>
                <div>
                  <Select
                    options={category?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptionscategory}
                    onChange={handleSelectChangecategory}
                  />
                </div>

                <span className="image_error_msg">
                  * Choose The Video Category
                </span>
              </div>
              <div className="col-sm-6 form-group">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label className="m-0">
                        Cast and Crew :{" "}
                        <small>( Add artists for the video below )</small>
                      </label>
                    </div>
                    <div className="panel-options">
                      <Select
                        options={artists?.map((option) => ({
                          value: option?.id,
                          label: option?.artist_name,
                        }))}
                        className="form-control"
                        isMulti
                        value={selectedOptionsartists}
                        onChange={handleSelectChangeartists}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Choose Language:</label>
                <div>
                  <Select
                    options={language?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptionslanguage}
                    onChange={handleSelectChangelanguage}
                  />
                </div>

                <span className="image_error_msg">* Choose The Language</span>
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  E-Paper: <small>(Upload your PDF file)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="pdf_file"
                  accept="application/pdf"
                  onChange={(e) => setPdf_files(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Reels Videos: </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_videos[]"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setReelvideo(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Reels Thumbnail: <small>(9:16 Ratio or 720X1080px)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_thumbnail"
                  onChange={(e) => setReels_thumbnail(e.target.files[0])}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">URL Link </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_link"
                  onChange={(e) => setUrl(e.target.value)}
                  id="url_link"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  URL Start Time <small>Format (HH:MM:SS)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_linktym"
                  onChange={(e) => setUrl_linktym(e.target.value)}
                  id="url_linktym"
                />
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleNextNext();
              }}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
    {
      label: "User Video Access",
      description: (
        <>
          {/* <div className="form-card mt-3">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="fs-title">Geo-location for Videos</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="m-0">Block Country </label>
              <p className="p1">
                ( Choose the countries for block the videos )
              </p>
              <div>
                <Select
                  options={block_country?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptions}
                  onChange={handleSelectChange}
                />
              </div>
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0"> Country </label>
              <p className="p1">( Choose the countries videos )</p>
              <div>
                <Select
                  options={available_country?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptionsAvailable_country}
                  onChange={handleSelectChangeAvailable_country}
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-6 form-group">
              <label for="">Search Tags </label>
              <input
                type="text"
                id="exist-values"
                className="tagged form-control"
                data-removeBtn="true"
                name="searchtags"
                onChange={(e) => setSearch_tags(e.target.value)}
              />
            </div>

            <div className="col-sm-6 form-group ">
              <label className="m-0">Related Videos:</label>
              <div>
                <Select
                  options={related_videos?.map((option) => ({
                    value: option?.id,
                    label: option?.title,
                  }))}
                  className="rs-input"
                  isMulti
                  value={selectedOptionsRelated_videos}
                  onChange={handleSelectChangeRelated_videos}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="m-0">User Access</label>
              <p className="p1">Who Is Allowed To View This Video ?</p>
              <select
                className="form-control"
                onChange={(e) => setUserAccess(e.target.value)}
                onClick={(event) => {
                  setLivesource(event.target.value);
                }}
              >
                {user_access?.map((item, key) => (
                  <option value={item?.role}>{item?.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div>{live_stream_source()}</div>

          <div className="row">
            <div className="col-sm-6 form-group mt-3" id="ppv_price">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  <label for="global_ppv">Is this video Is Global PPV:</label>
                </div>
                <div className="col-lg-2 col-3">
                  <label className="switch">
                    <input
                      name="global_ppv"
                      onChange={handleInput}
                      defaultChecked={global_ppv.global_ppv == 1 ? true : false}
                      checked={global_ppv.global_ppv == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="global_ppv"
                      onChange={handleInput}
                      value={global_ppv.global_ppv == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mt-3">
              <div className="panel panel-primary" data-collapsed="0">
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>
                      <h3 className="fs-title">Status Settings</h3>
                    </label>
                  </div>
                </div>

                <div className="panel-body">
                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="featured">
                        Enable this video as Featured:
                      </label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          defaultChecked={featured.featured == 1 ? true : false}
                          checked={featured.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={featured.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="active">Enable this Video:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active.active == 1 ? true : false}
                          checked={active.active == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="banner">Enable this Video as Slider:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          defaultChecked={banner.banner == 1 ? true : false}
                          checked={banner.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={banner.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNextNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <>
          {/* <div className="form-card mt-3">
          <div className="row">
            <div className="col-7">
              <h3 className="fs-title">Image Upload:</h3>
            </div>
            <div className="col-5"></div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Video Thumbnail <span>(9:16 Ratio or 1080X1920px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1080 x 1920 pixels dimension or
                  ratio 9:16{" "}
                </p>
              </span>
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="player_image"
                id="player_image"
                onChange={(e) => setPlayer_image(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1280 x 720 pixels dimension or
                  ratio 16:9{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video TV Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_tv_image"
                id="video_tv_image"
                onChange={(e) => setVideo_tv_image(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1920 x 1080 pixels dimension or
                  16:9 ratio{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video Title Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_title_image"
                id="video_title_image"
                onChange={(e) => setVideo_Title_Thumbnail(e.target.files[0])}
              />
            </div>

            <div className="col-sm-6 form-group">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  {" "}
                  <label className="mb-1">Enable Video Title Thumbnail</label>
                </div>
                <div className="col-lg-2 col-2">
                  <label className="switch">
                    <input
                      name="enable_video_title_image"
                      onChange={handleInput}
                      defaultChecked={
                        enable_video_title_image.enable_video_title_image == 1
                          ? true
                          : false
                      }
                      checked={
                        enable_video_title_image.enable_video_title_image == 1
                          ? true
                          : false
                      }
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_video_title_image"
                      onChange={handleInput}
                      value={
                        enable_video_title_image.enable_video_title_image == 1
                          ? "1"
                          : "0"
                      }
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-7">
              <h5 className="fs-title">Trailer Upload:</h5>
            </div>

            <div className="col-sm-6">
              <label className="m-0">Video Trailer Type:</label>
              <select
                className="trailer_type form-control"
                name="trailer_type"
                id="trailer_type"
                onChange={(e) => setTrailer_type(e.target.value)}
                onClick={(event) => {
                  setEnable_restreamtrailer(event.target.value);
                }}
              >
                <option value="null"> Select the Video Trailer Type </option>
                <option value="1"> Video Upload </option>
                <option value="m3u8_url">m3u8 Url </option>
                <option value="mp4_url">mp4 Url</option>
                <option value="embed_url">Embed Code</option>
              </select>
            </div>
          </div>

          <div>
            <div
              onClick={(event) => {
                setEnablestreamvisibletrailer(event.target.value);
              }}
            >
              {proceednexttrailer()}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8  form-group">
              <label className="m-0">Trailer Description:</label>
              <JoditEditor
                value={trailerdec}
                onChange={(newContent) =>
                  contentFieldChanagedtrailerdec(newContent)
                }
              />
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNextNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <>
          {/* <div className="form-card mt-3">
          <div className="row p-0 m-0">
            <div className="col-7">
              <h4 className="fs-title">ADS Management:</h4>
            </div>

            <div className="row text-start mt-4">
              <div className="col-sm-6">
                <label className="m-0">Choose Ads Position</label>

                <div className="">
                  <Select
                    options={adsPositions}
                    onChange={(e) => handleAdsPositionChange(e)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="m-0">Choose Advertisement</label>

                <div className="">
                  <Select
                    options={secondSelectOptions}
                    onChange={(e) => setSecondSelectOptions(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Submit
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="addvideo">
                  <Box>
                    {/* <Stepper activeStep={activeStep} nonLinear>
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index === 5 ? (
                                <Typography variant="caption"></Typography>
                              ) : null
                            }
                          >
                            {step.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper> */}

                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                      orientation="vertical"
                    >
                      {steps?.map((step, index) => (
                        <Step key={step.label}>
                          <StepContent>
                            <Typography>{step?.description}</Typography>
                            {/* <Box sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={handleNextNext}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-primary">
                                  {index === steps.length - 1 ? 'Upload Video' : 'Next'}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-dark">
                                  Back
                                </Button>
                              </div>
                            </Box> */}
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps?.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
